import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { Suspense, useState } from 'react';
import useLocalStorage from './Components/Utils/ReDirectRoute';
import Header from './Components/Common/Header';
import Sidebar from './Components/Common/Sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loader = React.lazy(()=> import('./Components/Common/Loader'));
const Login = React.lazy(() => import('./Components/SignUp-Login/Login'));
const Register = React.lazy(()=> import('./Components/SignUp-Login/Sign-up'));
const OTP = React.lazy(()=> import('./Components/SignUp-Login/Otp'));
const Dashboard = React.lazy(()=> import('./Components/Screens/Dashboard/Dashboard'));
const Board = React.lazy(()=> import('./Components/Screens/DataTables/Board_Management/Board'));
const Medium = React.lazy(()=> import('./Components/Screens/DataTables/Board_Management/Medium'));
const Classes = React.lazy(()=> import('./Components/Screens/DataTables/Board_Management/Class'));
const Subject = React.lazy(()=> import('./Components/Screens/DataTables/Subject'));
const Lesson = React.lazy(()=> import('./Components/Screens/DataTables/Lesson'));
const Users = React.lazy(()=> import('./Components/Screens/DataTables/Users'));
const Units = React.lazy(()=> import('./Components/Screens/DataTables/Units'));
const Questions = React.lazy(()=> import('./Components/Screens/DataTables/Questions'));
const Academy = React.lazy(()=> import('./Components/Screens/DataTables/Exam_management/Academy'));
const Score = React.lazy(()=> import('./Components/Screens/DataTables/Exam_management/Score'));
const Notice = React.lazy(()=> import('./Components/Screens/DataTables/Exam_management/Notice'));
const QuestionAssign = React.lazy(()=> import('./Components/Screens/DataTables/Exam_management/QuestionAssign'));
const Students = React.lazy(()=> import('./Components/Screens/DataTables/Students'));
const Sections = React.lazy(()=> import('./Components/Screens/DataTables/Board_Management/Section'));
const Schools = React.lazy(()=> import('./Components/Screens/DataTables/Board_Management/School'));
const Material = React.lazy(() =>import('./Components/Screens/DataTables/Material'))
function App() {

  const [LoginData , setLoginData] = useLocalStorage('LogData');
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const CheckLogin = (Component) => {
    const access = LoginData;
    if(!access){
      return <Navigate to="/login" />;
    }else{
      return (
        <>
        <Header toggleSidebar={toggleSidebar}/>
        <Sidebar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar}/>
        <div>
        <Component />
        </div>
      </>
      )
    }
  }

  const LogoutCheck = (Component) => {
    const access = LoginData;
    if(access){
      return <Navigate to='/dashboard' />;
    }else{
      return <Component />
    }
  }

  const routes = [
    { path: '/', element: CheckLogin(Dashboard) },
    { path: '/dashboard', element: CheckLogin(Dashboard) },
    { path:'/login', element : LogoutCheck(Login)},
    { path:'/register', element: LogoutCheck(Register)},
    { path:'/register-otp', element:LogoutCheck(OTP)},
    { path:'/users', element:CheckLogin(Users) },
    { path:'/board', element:CheckLogin(Board)},
    { path:'/medium', element:CheckLogin(Medium)},
    { path:'/class', element:CheckLogin(Classes) },
    { path:'/subject', element:CheckLogin(Subject) },
    { path:'/lessons', element:CheckLogin(Lesson) },
    { path:'/units', element:CheckLogin(Units) },
    { path:'/questions', element:CheckLogin(Questions) },
    { path:'/academy', element:CheckLogin(Academy) },
    { path:'/score', element:CheckLogin(Score) },
    { path:'/notice', element:CheckLogin(Notice) },
    { path:'/qa_assign', element:CheckLogin(QuestionAssign) },
    { path:'/students', element:CheckLogin(Students) },
    { path:'/sections', element:CheckLogin(Sections) },
    { path:'/schools', element:CheckLogin(Schools) },
    { path:'/materials', element:CheckLogin(Material) }


  ]

  return (
    <Router>
      <Suspense fallback={<Loader />}>
      <ToastContainer />
        <Routes>
          {routes.map((route, index) => (
             <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;

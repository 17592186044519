import { useEffect, useState } from "react";

function useLocalStorage(key){

    const [StoredValue, setStoredValue] = useState(()=>{
        try{
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : null;
        }catch(error){
            console.log(error);
            return null;
        }
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const item = localStorage.getItem(key);
            setStoredValue(item ? JSON.parse(item):null)
        };

        window.addEventListener('storage',handleStorageChange);
        return () => {
            window.removeEventListener('storage',handleStorageChange)
        };
    }, [key]);

    const setValue = (value) => {
        try{
            const valueToStore = value instanceof Function ? value(StoredValue) : value;
            setStoredValue(valueToStore);
            localStorage.setItem(key,JSON.stringify(valueToStore));
        }catch(error){
            console.log(error)
        }
    }

    return [StoredValue,setValue];
}

export default useLocalStorage;
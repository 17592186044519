import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CommonAlertModal from './CommonModal'

const Header = ({toggleSidebar}) => {

  const [modalOpen, setModalOpen] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('LogData')))
  
  const HandleLogout = () => {
    localStorage.clear()
    window.location.href = '/login';
  }

  return (
    <header>
    <div className="topbar d-flex align-items-center">
      <nav className="navbar navbar-expand gap-3">
        <div className="mobile-toggle-menu">
          <i className="bx bx-menu" onClick={toggleSidebar}/>
        </div>
        <div
          className="search-bar d-lg-block d-none"
          data-bs-toggle="modal"
          data-bs-target="#SearchModal"
        >
          <a href="javascript:;" className="btn d-flex align-items-center">
            <i className="bx bx-search" />
            Search
          </a>
        </div>
        <div className="top-menu ms-auto">
          <ul className="navbar-nav align-items-center gap-1">
            <li
              className="nav-item mobile-search-icon d-flex d-lg-none"
              data-bs-toggle="modal"
              data-bs-target="#SearchModal"
            >
              <a className="nav-link" href="javascript:;">
                <i className="bx bx-search" />
              </a>
            </li>
          </ul>
        </div>
        <div className="user-box dropdown px-3">
          <a
            className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="/assets/assets/images/avatars/avatar-2.png"
              className="user-img"
              alt="user avatar"
            />
            <div className="user-info">
              <p className="user-name mb-0">{user.first_name}&nbsp;{user.last_name}</p>
              <p className="designattion mb-0">{user.email}</p>
            </div>
          </a>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <a
                className="dropdown-item d-flex align-items-center"
                href="javascript:;"
              >
                <i className="bx bx-cog fs-5" />
                <span>Settings</span>
              </a>
            </li>
            <li>
              <a
                className="dropdown-item d-flex align-items-center"
                href="javascript:;"
              >
                <i className="bx bx-home-circle fs-5" />
                <span>Dashboard</span>
              </a>
            </li>
            <li>
              <div className="dropdown-divider mb-0" />
            </li>
            <li>
              <a
                className="dropdown-item d-flex align-items-center"
                href="javascript:;"
                onClick={()=>setModalOpen(true)}
              >
                <i className="bx bx-log-out-circle" />
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <CommonAlertModal icon={'/icons/ask.png'} title={'Log out?'}
      desc={'Confirm to Log out'} submitText={'Log out'}
    submitAction={()=>HandleLogout()} setShowModal={()=>setModalOpen(false)} showModal={modalOpen} />

  </header>

  )
}

export default Header